<template>
  <div class="flix-row">
    <div class="flix-col-md-5">
      <div class="flix-form-group flix-well">
        <h1>{{ $tc('message.calendar', 1) }}</h1>
        <h4 class="flix-html-h4">{{ JSON.parse(data.atitle).title }}</h4>
        <notes />
      </div>
      <div class="flix-form-group flix-well">
        <h1>{{ $tc('message.personalData') }}</h1>
        <showData :data="data" />
      </div>
    </div>
    <div class="flix-col-md-7">
      <div class="flix-well flix-form-group">
        <h1>{{ $tc('message.appointmentOverview', 1) }}</h1>
        <h4 class="flix-html-h4">{{ $t('message.editNumber') }}</h4>
        {{ data.booking_id }}
        <h4 class="flix-html-h4">{{ $t('message.editStatus') }}</h4>
        <div class="flix-form-group">
          <span :class="'flix-html-h5 flix-bg-'+$getStatusClass(data.status)+' flix-text-'+$getStatusClass(data.status)" style="padding: 5px;">{{ $t('message.' + data.status) }}</span>
        </div>
        <div class="flix-form-group" v-if="data.status === 'open'">
          <span class="flix-alert flix-alert-info">{{ $t('message.appointmentOpenMsg') }}</span>
        </div>
        <note :data="data" />
        <div class="flix-form-group">
          <h4 class="flix-html-h4">{{ $tc('message.appointments', 1) }}</h4>
          <span v-html="data.booking_date" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    showData () { return import('@/components/bookingInfoPage/components/data.vue') },
    notes () { return import('@/components/bookingInfoPage/details/note') }
  },
  props: {
    data: Object
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>
